<template>
  <div class="markup-tables flex">
    <va-card class="flex mb-4">
      <va-card-title style="font-size: 1.5rem;">Frontal Image Grid</va-card-title>
      <va-card-content>
        <div class="row">
          <va-card class="flex xs3 md3 offset--md1" style="margin-bottom: 1.5rem;" v-for="image in gridImages" :key="image.uid">
            <va-card-title>
              {{ image.name }}
              <br />{{ image.created_at }}
            </va-card-title>
            <va-card-content>
              <router-link :to="{ name: 'file', params: { id: image.uid, fileId: image.image_id }}">
                <img style="width: 100%;" :src="image.jpg" />
              </router-link>
            </va-card-content>
          </va-card>
        </div>
      </va-card-content>
    </va-card>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'

export default {
  mounted: async function () {
    if (this.selectedItems.length > 0) {
      this.loader = this.$loading.show()
      this.gridImages = []
      try {
        const headers = {
          Authorization: `Bearer ${this.token}`
        }
        await Promise.all(this.selectedItems.map(async (selectedItem) => {
          const images = await axios.get(`${process.env.VUE_APP_API_URL}/api/sessions/${selectedItem.uid}/images`, { headers })
          let frontalImage = null
          await Promise.all(images.data.images.map((image) => {
            if (image.type === 'Frontal') {
              frontalImage = image.uid
            }
          }))

          if (frontalImage) {
            const image = await axios.get(`${process.env.VUE_APP_API_URL}/api/sessions/${selectedItem.uid}/images/${frontalImage}/download`, { headers })
            this.gridImages.push({
              uid: selectedItem.uid,
              image_id: frontalImage,
              jpg: image.data.image.flash.jpg,
              name: image.data.image.name,
              created_at: moment(image.data.image.created_at).format('YYYY-MM-DD hh:mm a')
            })
          }
        }))
        this.gridImages = _.orderBy(this.gridImages, ['created_at'], ['desc'])
        this.loader.hide()
      } catch (error) {
        this.loader.hide()
        this.$router.push({ name: 'login', query: { redirect: '/pages/sessions' } })
      }
    } else {
      this.$router.push({ name: 'sessions' })
    }
  },
  data () {
    return {
      loader: null,
      token: this.$store.state.token,
      selectedItems: this.$store.state.selectedItems,
      gridImages: []
    }
  },
  computed: {
  },
  methods: {
  },
}
</script>

<style lang="scss">
  .markup-tables {
    .table-wrapper {
      overflow: auto;
    }

    .va-table {
      width: 100%;
    }
  }

  .table-example--pagination {
    text-align: center;
    text-align: -webkit-center;
    padding-top: 1rem;
  }
</style>
